@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

$red: #bd1428;
$mint: #92ccba;
$white: #fff;
$black: #000;
$break-large: 1025px;
$break-medium: 768px;

html{
    @media screen and (min-width: $break-medium){
        background:
            url('../img/crane_bg2.png') left bottom no-repeat fixed,
            url('../img/hook_bg2.png') right top no-repeat fixed;
        background-color: #b3dbcf;
    }
}
body{
    background: none;
}
h2, h3, h6{;
    font-family: "proxima-nova",sans-serif;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.8rem;
    text-align: center;
    margin: 20px 0;
}
h2{
    padding: 0 5%;
    margin: 40px 0;
    font-size: 2.2rem;
}
h3{
    font-size: 1rem;
    text-align: left;
    color: $mint;
}
h6{
    color: $white;
    font-weight: normal;
    display: inline-block;
    padding: 5px 10px;
    background: $mint;
    margin-top: 0;
    width: 180px;
    margin-left: calc(50% - 90px);
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 4px;
}
p + h3{
    margin-top: 0;
}
p, table, label, input, textarea, li{
    font-family: "proxima-nova",sans-serif;
}
p:last-child{
    margin-bottom: 0
}
form[name="contactform"]{
    label{
        margin-bottom: -20px;
    }
    select {
      transition: box-shadow .5s,border-color .25s ease-in-out;
    }
    input, textarea, select{
        background: none;
        box-shadow: 0 0 0 $white;
        margin: 0;
    }
    input:focus, textarea:focus, select:focus{
        border: 1px solid $mint;
        outline: 1px solid $mint;
        box-shadow: 0 0 10px $mint;
        background: none;
    }
    input[type="submit"]{
        width: 100%;
        border: none;
        background: $red;
        color: $white;
        height: 50px;
        font-size: 1.5rem;
        font-weight: bold;
    }
}
::-moz-selection {
    color: $white;
    background: $mint;
}
::selection {
    color: $white;
    background: $mint;
}
blockquote{
    font-family: "proxima-nova",sans-serif;
    font-weight: 800;
    color: $white;
    text-align: center;
    font-size: 2.5rem;
    padding: 0;
    margin: 0;
    border: none;
    text-transform: uppercase;
    line-height: 1;
}
a[href="mailto"]{
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
}
section{
    position: relative;
    padding: 0;
    padding-top: 0;
    border-top: 1px solid $mint;
    overflow-y: hidden;
    //max-height: 0;
    transition: max-height 1s ease-out;
    h2{
        margin: 10px 0 40px;
        @media screen and (max-width: $break-medium){
            font-size: 1.5rem;
        }
    }
    @media screen and (min-width: $break-medium){
        padding: 50px;
    }
}
section.open{
    max-height: 4000px !important;
    transition: max-height 3s ease-out;
}
section:first-of-type{
    border-top: none;
    margin-top: 40px;
}
footer{
    margin-top: 0;
    background: #000;
    padding: 30px 0;
    ul{
        padding: 0 20px;
        margin: 0;
        list-style: none;
        li{
            display: inline-block;
            padding: 0 20px;
            color: $white;
            a{
                color: $white;
                text-transform: uppercase;
                font-size: 0.8rem;
                font-weight: 500;
                &:hover{
                    color: $mint
                }
            }
        }
        li:last-child{
            text-transform: uppercase;
            font-size: 0.8rem;
        }
    }
}
a{
    color: $red;
}
a:hover{
    color: #7c0a18;
}
img{
    max-width: calc(100% + 30px);
    margin-left: -15px;
    @media screen and (min-width: $break-medium){
        max-width: 100%;
        margin-left: 0;
    }
}
.wf-loading{
    opacity: 0;
}
.wf-active{
    opacity: 1;
}
.red-text{
    color: $red;
}
.red-quote{
    padding-left: 10px;
    position: relative;
    font-family: "jubilat",sans-serif;
    font-style: normal;
    font-weight: 500;
    width: 80%;
    margin-left: 10%;
    font-size: 1.5rem;
    text-align: left;
    line-height: 1.2;
    padding-top: 23px;
    padding-bottom: 23px;
    text-indent: -15px;
    @media screen and (min-width: $break-medium){
        font-size: 1.7rem
    }
}
.red-quote:before{
    content: '"  ';
    display: inline;
    position: relative;
    left: -5px
}
.red-quote:after{
    content: ' "';
}
.clearfix{
    display: block;
    clear: both;
}
.two-col-text{
    p{
        margin: 0 1rem 1rem 1rem;
    }
    @media screen and (min-width: $break-medium){
        -webkit-column-count: 2;
        -webkit-column-gap: 20px;
    }
}
.black-text{
    color: #000;
}
.red-text{
    color: $red;
}
blockquote.black-text{
    padding: 0 5%;
    font-size: 2rem;
    margin-top: 4rem;
    margin-bottom: 5rem;
}
.square{
    width: 100%;
    min-height: 100px;
}
.icon{
    width: 40%;
    margin: 48px 30% 16px 30%;
}
.colored-textblock{
    padding: 4rem !important;
    background: $mint;
    color: $white;
}
.example{
    font-style: italic;
}
.padding-15{
    padding-left: 15px;
}
.button.expanded{
    width: calc(100% - 30px);
}
.caption{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    margin: 20px 0;
    padding-bottom: 20px;
}
.row{
    margin-top:30px;
}
.startgrid{
    margin-top: 0;
    p{
        padding:40px;
    }
}
.bold{
    font-weight: bold;
    color: $mint;
    text-transform: uppercase;
}
.full-width-img{
    margin-top: 0;
    max-width: calc(100% + 30px);
    margin-left: -15px;
    @media screen and (min-width: $break-medium){
        max-width: 100%;
        margin-top: 40px;
        margin-left: 0;
    }
}
.has-caption-fr, .has-caption-nl, .has-caption-en{
    position: relative;
}
.has-caption-fr:before{
    content: "...";
    position: absolute;
    bottom: 0px;
    right: -15px;
    font-size: 2rem;
    font-family: "proxima-nova",sans-serif;
    font-weight: bold;
    background: rgba(255,255,255,0.4);
    padding: 0 10px;
    color: #333;
    @media screen and (min-width: $break-medium){
        right: 0;
    }
}
.caption-local{
    position: absolute;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 20px;
    color: $white;
    top: 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4;
    background: rgba(0,0,0,0.2);
    opacity: 1;
    transition: opacity 0.3s;
    @media screen and (min-width: $break-medium){
        width: 100%;
        margin-left: 0;
    }
}
.caption-local-hidden{
    opacity: 0;
    transition: opacity 0.3s;
}
.grid-paragraph{
    padding: 20px;
    display: block;
    height: 100%;
    background: $mint;
    color: $white;
    font-weight: 500;
    font-size: 0.9rem;
    width: calc(100% + 30px);
    margin-left: -15px;
    @media screen and (min-width: $break-medium){
        width: 100%;
        margin-left: 0;
    }
    @media screen and (min-width: $break-large){
        font-size: 1.1rem;
        width: 100%;
        margin-left: 0;
    }
}
.wharf{
    border: 1px solid $mint;
    padding: 50px 0;
    h2{
        margin-bottom: 0;
    }
    h3{
        text-align: center;
        margin-top: 0;
    }
    img{
        width: 100px;
        margin-left: calc(50% - 50px);
        margin-bottom: 20px
    }
    div.columns:nth-child(n+3){
        margin-top: 50px;
    }
    div.columns:nth-child(odd){
        border-right: none;
        @media screen and (min-width: $break-medium){
            border-right: 1px solid $mint;
        }
    }
}
img.arrow-down{
    width: 50px;
    margin-left: calc(50% - 25px);
}
img.dispo{
    background: $mint;
    padding: 5% 25%;
}
p.copyright{
    position: absolute;
    color: $white;
    bottom: 5px;
    right: 20px;
    font-size: 0.8rem;
    @media screen and (min-width: $break-medium){
        bottom: 0px;
        right: 20px;
    }
}
p.styles{
    text-transform: lowercase;
    margin-top: 20px
}
p.styles:first-letter{
    text-transform: uppercase
}
p.intro{
    font-weight: bold;
    margin-bottom: 20px;
    @media screen and (min-width: $break-medium){
        padding: 0;
    }
}
p.readless, p.readmore{
    cursor: pointer;
    opacity: 1;
    color: $mint;
    font-weight: bold;
    margin-top: 2rem;
    transition: opacity
}
p.readless{
    margin-top: 2rem;
}
p.readmore{
    margin-left: 65px;
    width: calc(100% - 130px);
}
p.readmore.hidden{
    display: none;
}
.inline-img{
    margin-bottom: 1rem;
}
.four-styles{
    position: relative;
    &:last-child{
        margin-right: 0;
    }
    blockquote{
        position: absolute;
        top: 70px;
        width: calc(100% - 0.9375rem * 2);
        text-align: center;
        z-index: 10;
        font-size: 2rem;
        text-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
}
.current{
    font-weight: 900
}
.sub-h2{
    font-weight: 300;
    font-size: 1rem;
}
.fixed-height{
    height: 150px;
    width: 100%;
}
#agents{
    .columns{
        position: relative;
        height: 190px;
        h3{
            position: absolute;
            bottom: 0;
            color: $red;
            width: 100%;
        }
    }
    div.hidden{
        display: none;
    }
}
#disclaimer{
    max-height: 0;
    overflow: hidden;
    padding: 0 15px;
    margin: 0;
    transition: max-height 0.3s;
    p{
        margin-bottom: 20px;
    }
}
#disclaimer.active{
    max-height: 1000px;
    transition: max-height 0.3s;
}
#nav-home{
    padding: 0 15px;
}
#wharf_b{
    margin-top: 30px;
    @media screen and (min-width: $break-medium){
        margin-top: 0;
    }
}
#app{
    margin-bottom: 50px;
    h2{
        margin-bottom: 5px;
        text-shadow: 3px 3px 0 rgba(255,0,0,0.4), -3px -3px 0 rgba(0,0,150,0.4);
    };
    img{
        margin-left: calc(50% - 90px);
        width: 180px;
        padding: 15px;
        @media screen and (min-width: $break-medium){
            margin-left: 0;
        }
    };
    a:first-of-type img{
        @media screen and (min-width: $break-medium){
            margin-left: calc(50% - 180px);
        }
    }
}
#partners{
    background: $mint;
    padding: 10px 0;
    margin-top: 50px;
    margin-bottom: 0;
    width: calc(100% + 30px);
    margin-left: -15px;
    p{
        margin-bottom: 15px;
        line-height: 1.2;
        padding-top: 8px;
        text-align: center;
        @media screen and (min-width: $break-medium){
            margin-bottom: 0;
            text-align: left;
        }
    }
    img{
        width: 30%;
        margin-left: 10%;
        @media screen and (min-width: $break-medium){
            width: 20%;
            margin-right: 15%
        }
    }
    img:last-child{
        width: 40%;
        @media screen and (min-width: $break-medium){
            width: 30%;
            margin-right: 0
        }
    }
    @media screen and (min-width: $break-medium){
        width: 100%;
        margin-left: 0
    }
}
#map{
    width: 100%;
    height: 400px;
    margin-top: 50px;
}
#note{
    font-size: 0.85rem;
    color: $mint;
}
#appartments-h3{
    @media screen and (min-width: $break-medium){
        margin-top: 0 !important;
    }
}
#area-map{
    padding: 0;
    margin-top: 2rem;
    @media screen and (min-width: $break-medium){
        margin-top: -20px;
    }
}
#concept-quote, #appart-quote{
    text-align: left;
    font-size: 1.7rem;
    line-height: 1.2;
    padding-top: 23px;
    padding-bottom: 23px;
}
#concept-quote{
    text-align: left;
}
#appart-quote{
    text-align: center;
    padding: 20px 0;
    @media screen and (min-width: $break-medium){
        text-align: right;
        padding: 50px 0;
    }
}
#lang-select-img{
    width: 100%;
    @media screen and (min-width: $break-medium){
        width: 50%;
        margin-left: 25%;
        margin-top: 25%;
    }
    @media screen and (min-width: $break-large){
        margin-top: 5vh;
    }
}
#lang-select{
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        @media screen and (min-width: $break-medium){
            width: 100%;
        }
        li{
            box-sizing: border-box;
            border: none;
            width: 100%;
            margin: 0;
            padding: 0;
            text-align: center;
            text-transform: uppercase;
            @media screen and (min-width: $break-medium){
                display: inline-block;
                width: 30%;
                float: left;
                margin-left: 2.5%;
            }
            a{
                display: block;
                padding: 25px 0;
                font-size: 1.2rem;
                color: #000;
                @media screen and (min-width: $break-medium){
                    border: 1px solid $white;
                    background: none;
                    color: $white;
                    transition: all 0.4s;
                }
            }
            a:hover{
                @media screen and (min-width: $break-medium){
                    border: 1px solid $white;
                    background: $white;
                    color: $mint;
                    transition: all 0.4s;
                }
            }
        }
    }
}
#wrapper{
    background: #fff;
    position: relative;
    overflow-x: hidden;
    max-width: 1024px;
    margin: auto;
    right:0;
    padding: 0 /*15px*/;
    transition: right 0.6s;
    box-shadow: 0 0 30px rgba(0,0,0,0.4);
    @media screen and (min-width: $break-medium){
            padding: 0 1rem;
    }
    #header{
        position: relative;
        width: 100%;
        min-height: 185px;
        img{
            max-width: 50%;
            margin: 0 25%;
            @media screen and (min-width: $break-large){
                width: 30%;
                margin: -15px 35%
            }
        }
        nav{
            font-family: "proxima-nova",sans-serif;
            position: fixed;
            right: -100%;
            top: 0;
            width: 100%;
            //height: 0px;
            background: $white;
            transition: right 0.6s;
            @media screen and (min-width: $break-medium){
                position: relative;
                right: 0;
                //min-height: initial;
            }
            ul{
                list-style: none;
                height: 100%;
                margin-top: 2rem;
                margin-bottom: 20px;
                li{
                    width: 100%;
                    text-align: center;
                    padding: 2vh 0;
                    transition: all 0.3s;
                    @media screen and (min-width: $break-medium){
                        position: relative;
                        padding: 0;
                        height: initial;
                        width: calc(100% / 7.001);
                        text-align: center
                    }
                    a{
                        width: 100%;
                        color: #000;
                        font-size: 1.6rem;
                        text-transform: uppercase;
                        font-weight: lighter;
                        @media screen and (min-width: $break-medium){
                            font-size: 0.8rem
                        }
                        @media screen and (min-width: $break-large){
                            font-size: 1rem
                        }
                    }
                }
                li:hover{
                    background: #000;
                    transition: all 0.3s;
                    a{
                        color: $white
                    }
                }
                li:before{
                    @media screen and (min-width: $break-medium){
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 100%;
                        left: -1px;
                        top: 0;
                        background: none;
                        transition: all 0.3s;
                    }
                }
                li:hover:before{
                    @media screen and (min-width: $break-medium){
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 100%;
                        left: -1px;
                        top: 0;
                        background: #000;
                        transition: all 0.3s;
                    }
                }
                li:after{
                    @media screen and (min-width: $break-medium){
                        content: '';
                        position: absolute;
                        display: block;
                        top: 0.4rem;
                        right: 0;
                        height: 0.8rem;
                        border-right: 1px solid #000;
                    }
                }
                li:last-child:after{
                    @media screen and (min-width: $break-medium){
                        content: '';
                        border-right: none
                    }
                }
                @media screen and (min-width: $break-medium){
                    margin-top: 0;
                }
            }
            #close-mobile-menu-trigger{
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                height: 50px;
                width: 50px;
                text-align: center;
                background: $mint;
                color: $white;
                font-weight: bold;
                font-size: 2rem;
                margin: 0;
            }
        }
        nav.open{
            right: 0;
            transition: right 0.6s;
        }
        #open-mobile-menu-trigger{
            display: inline-block;
            color: $mint;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            border: 0.15rem solid $mint;
            padding: 0.3rem;
            width: 80px;
            margin-left: calc(50% - 40px);
            margin-bottom: 1rem;
            z-index: 9;
            &.setMenuFixed{
                    position: fixed;
                    background: #fff;
                    right: -23px;
                    top: 6rem;
                    transform: rotateZ(-90deg);
            }
        }
    }
    #home-grid{
        background:  $white;
        blockquote{
            text-shadow: 0 0 10px rgba(0,0,0,0.5);
        }
        div.row{
            min-height: 250px;
        }
        h1{
            min-height: 250px;
            height: 100%;
            width: 100%;
            font-family: "proxima-nova",sans-serif;
            font-style: normal;
            font-weight: 800;
            text-transform: uppercase;
            font-size: 3rem;
            line-height: 0.75;
            color: $white;
            background: url('../img/crane.png') no-repeat left top;
            background-size: cover;
            padding: 5%;
            margin-bottom: 0;
            @media screen and (min-width: $break-medium){
                font-size: 5rem
            }
            @media screen and (min-width: $break-large){
                font-size: 8.5rem
            }
            &.homepage-news{
                min-height: 0;
                font-size: 1.5rem;
                line-height: 1;
                background: none;
                @media screen and (min-width: $break-medium){
                    font-size: 2rem;
                }
            }
        }
        h3{
            font-weight: 300;
            color: $white;
            padding: 5%;
            span.bold{
                color: $white;
                font-weight: bold;
            }
            span.newsdate{
                padding-bottom: 5%;
                border-bottom: 1px solid $white;
            }
        }
        div[id^="row"]{
            min-height: 250px;
            height: 100%;
        }
        #row-1-block-2{
            background: $red;
            background-size: cover;
        }
        #row-2-block-1{
            background: url('../img/Vue1_immeuble_HD_ciel bleu.jpg') center center no-repeat;
            background-size: cover;
        }
        #row-2-block-2{
            background: $white;
            height: auto;
            padding: 0px;
        }
        #row-2-block-3{
            height: 500px;
            width: 100%;
            background: url('../img/row2block3.jpg') bottom no-repeat;
            background-size: cover
        }
        #row-3-block-1{
            float: left;
            background: url('../img/cw3.jpg') left center no-repeat;
            background-size: cover;
        }
        #row-4-block-1{
            background: url('../img/cw1NB_gray.jpg') center center no-repeat;
            background-size: cover;
            @media screen and (min-width: $break-large){
                background: url('../img/cw1NB_gray.jpg') -210px center no-repeat;
                background-size: cover;
            }
        }
        #row-4-block-2{
            background: url('../img/Vue_Bat_B_HD-red.jpg') center center no-repeat;
            background-size: cover;
        }
        #row-5-block-1{
            background: url('../img/row5block1.jpg') center center no-repeat;
            background-size: cover;
            @media screen and (min-width: $break-large){
                background: url('../img/row5block1.jpg') center -50px no-repeat;
                background-size: cover;
            }
        }
        #row-6-block-1{
            background: url('../img/row6block1.jpg') center center no-repeat;
            background-size: cover;
        }
        #row-6-block-2{
            background: url('../img/Vue6_grue nuit_HD_web.jpg') center center no-repeat;
            background-size: cover;
            @media screen and (min-width: $break-medium){
                background: url('../img/Vue6_grue nuit_HD_web.jpg') left top no-repeat;
                background-size: 120%;
            }
        }
        #row-7-block-1{
            background: url('../img/row7block1.jpg') center center no-repeat;
            background-size: cover;
        }
        #row-7-block-2{
            background: url('../img/local1.jpg') center center no-repeat;
            background-size: cover;
            @media screen and (min-width: $break-medium){
                background: url('../img/local1.jpg') -140px bottom no-repeat;
                background-size: 200%;
            }
        }
    }
}
#wrapper.open{
    right: 100%;
    transition: right 0.6s;
}
#plan-viewer{
    overflow: hidden;
    position: relative;
    min-height: 300px;
    img:nth-child(1){
        position: absolute;
        top: 0;
        left: 100%;
        transition: all 0.2s;
    }
    img:nth-child(2){
        position: absolute;
        top: 100%;
        left: 0;
        transition: all 0.2s;
    }
    img:nth-child(3){
        position: absolute;
        top: 0;
        left: -100%;
        transition: all 0.2s;
    }
    img:nth-child(4){
        position: absolute;
        top: -100%;
        left: 0;
        transition: all 0.2s;
    }
    img.visible{
        top: 0;
        left: 0;
        transition: all 0.2s;
    }
}
table#availability, table.investment{
    margin-top: 2rem;
    border: none;
    font-size: 0.9rem;
}
table.investment{
    tbody{
        border: none;
        tr{
            background: $white;
            border-top: 1px solid #999;
            td{
                text-align: left
            }
            td:last-child{
                text-align: right
            }
        }
        tr.unavailable{
            color: #aaa;
        }
    }
}
table#availability{
    thead{
        border: none;
        background: $white;
        tr{
            td{
                text-align: center
            }
        }
    }
    tbody{
        border: none;
        tr{
            background: $white;
            border-top: 1px solid #999;
            td{
                text-align: center
            }
        }
        tr.unavailable{
            color: #aaa;
        }
    }
    .hidden{
        display:none;
    }
}
#show-all{
    text-transform: uppercase;
    font-family: "proxima-nova",sans-serif;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1rem;
    color: $mint;
    border: 1px solid $mint;
    padding: 0.5rem
}
#invest-side-title {
    color: $white;
    margin: 0;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 1.9rem;
    display: table-cell;
    vertical-align: middle;
}
#argument-list {
  padding: 0 10%;
  .argument {
    margin: 1rem;
    padding-top: 2rem;
    border-top: 1px solid $mint;
    &:first-child {
      border-top: none;
      margin-top: 0;
    }
    h4 {
      font-family: "proxima-nova",sans-serif;
      font-weight: bold;
      text-align: left;
      color: $red;
      br {
        display: none;
        @media screen and (min-width: $break-medium) {
          display: inline;
        }
      }
      span {
        display: inline;
        @media screen and (min-width: $break-medium) {
          display: none;
        }
      }
    }
    p {
      margin-bottom: 1rem;
      @media screen and (min-width: $break-medium) {
        // padding-left: 2rem;
        // border-left: 1px solid $black;
        border-color: $red;
      }
    }
  }
}

//Slick
/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    height: 100%;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

button.slick-prev, button.slick-next{
    position: absolute;
    top: 50%;
    z-index: 100;
    padding: 0.75rem 0.6rem 1rem;
    background: $white;
    color: $red;
    font-weight: bold;
    font-size: 1.2rem;
}
button.slick-prev{
    left: 0;
}
button.slick-next{
    right: 0;
}
//End Slick
